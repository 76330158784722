import * as elmAuth from './Internal/auth'
import * as elmFirestore from './Internal/firestore'

export const registerElmFirebasePorts = app => {
  // Get queries to execute
  app.ports.elmFirebaseSend.subscribe(json => {
    const request = JSON.parse(json)
    const { requestType } = request
    const elmFirebaseReceivePortSend = app.ports.elmFirebaseReceive.send

    switch (requestType) {
      case 'auth':
        return elmAuth.handle(elmFirebaseReceivePortSend, request)

      case 'firestore':
        return elmFirestore.handle(elmFirebaseReceivePortSend, request)

      default:
        console.error(`Elm-Firebase port unknown requestType: ${requestType}`)
    }
  })

  // Stop listening to queries
  // Elm don't expose the port unless it is used in Main
  if (app.ports.elmFirestoreStop) {
    app.ports.elmFirestoreStop.subscribe(elmFirestore.unsubscribe)
  }
}

/** * Helpers ***/
export const toUserType = elmAuth.toUserType
export const beforeSignOut = elmAuth.beforeSignOut
