import { firebaseFacesStorage } from '../../index.js'

export const faceVerificationPort = (device, app) => {
  app.ports.uploadFacePhoto.subscribe(({ userID, imageData }) => {
    firebaseFacesStorage
      .ref(`/users/${userID}`)
      .putString(imageData, 'base64', { contentType: 'image/jpg' })
      .then(_ => app.ports.uploadFacePhotoResult.send(null))
      .catch(error => app.ports.uploadFacePhotoResult.send(error.message))
  })
}
