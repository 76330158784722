import 'normalize.css'
import 'animate.css'
import './main.css'
import { unregister } from './registerServiceWorker'
import { Elm } from './Main.elm'
import { profilePort } from '../../core/src/Core/Data/Profile/profile.js'
import { messagePort } from './Data/Chat/message.js'
import { onlineStatusHandler } from './Ports/Js/onlineStatus.js'
import { membershipPort } from './Ports/Js/membership.js'
import { faceVerificationPort } from './Ports/Js/faceVerification.js'
import { contentPort } from './Ports/Js/content.js'
import {
  initialPushNotificationJSON,
  initialDevice,
  nativeVersion,
  nativeBridgePort
} from '../lib/elm-nativebridge/src/nativeBridge.js'
import { registerElmFirebasePorts } from '../lib/elm-firebase/src/Firebase/ports'

// Don't use service worker anymore
unregister()

// Export main Elm app
const rootDiv = document.getElementById('root')
const preRenderedHtml = rootDiv.innerHTML

// Get API url
const apiUrl = process.env.ELM_APP_API_DOMAIN_URL

// Firebase dependencies are loaded in index.html
// so that even in static pages, we have the analytics and performance
window.CoreFirebase = window.firebase
window.firebase.initializeApp({
  apiKey: process.env.ELM_APP_FIREBASE_API_KEY,
  authDomain: process.env.ELM_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.ELM_APP_FIREBASE_PROJECT_ID,
  databaseURL: process.env.ELM_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.ELM_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.ELM_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.ELM_APP_FIREBASE_APP_ID,
  measurementId: process.env.ELM_APP_FIREBASE_MEASUREMENT_ID
})

window.firebase.analytics() // Initialize Firebase Google Analytics
window.firebase.performance() // Initialize Firebase performance
const firebaseApp = window.firebase.app()
export const firebaseAuth = firebaseApp.auth()
export const firebaseStorage = window.firebase.storage()
export const firebaseFacesStorage = firebaseApp.storage(
  process.env.ELM_APP_FIREBASE_FACES_STORAGE_BUCKET
)
export const db = window.firebase.firestore()
export const dbTimestamp = window.firebase.firestore.FieldValue.serverTimestamp()
export const firebaseDb = window.firebase.database() // used for onDisconnect
if (window.location.hostname === 'localhost') {
  // use firestore emulator when running in localhost
  db.settings({
    host: 'localhost:8080',
    ssl: false,
    experimentalForceLongPolling: true // Required to work in Cypress: https://github.com/cypress-io/cypress/issues/6350
  })
}

Promise.all([
  // Start Firestore
  // There is an ongoing critical bug in iOS 12 and 13 that prevent Firestore's persistence from functioning properly
  // TODO: To turn on when Firebase has fixed persistence
  // https://github.com/firebase/firebase-js-sdk/issues/2232
  // https://github.com/firebase/firebase-js-sdk/issues/1670
  // db.enablePersistence({ synchronizeTabs: true }),
  // Firebase Auth is still initializing so we can't use firebase.auth().currentUser
  new Promise(resolve => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    })
  })
])
  .then(([currentUser]) => {
    const device = initialDevice()
    const appVersion = nativeVersion()
    const localBundleID = getLocalBundleID()
    const flags = {
      firebase: {
        apiKey: process.env.ELM_APP_FIREBASE_API_KEY,
        projectId: process.env.ELM_APP_FIREBASE_PROJECT_ID,
        authDomain: process.env.ELM_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.ELM_APP_FIREBASE_DATABASE_URL,
        storageBucket: process.env.ELM_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.ELM_APP_FIREBASE_MESSAGING_SENDER_ID
      },
      adminUserIDs: {
        male: process.env.ELM_APP_MALE_ADMIN_USER_ID,
        female: process.env.ELM_APP_FEMALE_ADMIN_USER_ID
      },
      apiUrls: {
        getRandomProfilesUrl:
          apiUrl + process.env.ELM_APP_GET_RANDOM_PROFILE_URL,
        sendEmailVerificationUrl:
          apiUrl + process.env.ELM_APP_SEND_EMAIL_VERIFICATION_URL,
        membershipDowngradeUrl:
          apiUrl + process.env.ELM_APP_MEMBERSHIP_DOWNGRADE_URL,
        membershipCancelUrl: apiUrl + process.env.ELM_APP_MEMBERSHIP_CANCEL_URL,
        membershipReactivateUrl:
          apiUrl + process.env.ELM_APP_MEMBERSHIP_REACTIVATE_URL,
        iOsCheckReceiptUrl:
          apiUrl + process.env.ELM_APP_MEMBERSHIP_IOS_CHECK_RECEIPT_URL,
        androidCheckTokenUrl:
          apiUrl + process.env.ELM_APP_MEMBERSHIP_ANDROID_CHECK_TOKEN_URL
      },
      multiCardsConfig: {
        merchantID: process.env.ELM_APP_MULTICARDS_MERCHANT_ID,
        pageOrderID: process.env.ELM_APP_MULTICARDS_PAGE_ORDER_ID,
        checkoutUrl: process.env.ELM_APP_MULTICARDS_CHECKOUT_URL
      },
      initialPushNotification: initialPushNotificationJSON(),
      preRenderedHtml: preRenderedHtml,
      bundleID: localBundleID,
      currentTime: Math.floor(Date.now()),
      timezoneOffset: new Date().getTimezoneOffset() * -1, // fucking messy: https://stackoverflow.com/questions/21102435/why-does-javascript-date-gettimezoneoffset-consider-0500-as-a-positive-off
      device: device,
      appVersion: appVersion,
      authUser: currentUser,
      dimension: {
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight
      }
    }
    rootDiv.remove()
    const app = Elm.Main.init({
      node: document.getElementById('root'),
      flags: flags
    })

    // Server-Side Rendered HTML does not load Elm
    // We set isUserLoggedIn flag in localStorage
    // so that an injected JS in deployment will load Elm
    // if the user is already logged in
    // See: /scripts/deploy/injectJSStaticHtml.js
    firebaseAuth.onAuthStateChanged(user => {
      localStorage.setItem('isUserLoggedIn', user == null ? 'false' : 'true')
    })

    // Load Online Status script
    onlineStatusHandler(firebaseAuth, firebaseDb)

    // Register all ports
    contentPort(app)
    faceVerificationPort(device, app)
    registerElmFirebasePorts(app)
    messagePort(app)
    profilePort(app)
    membershipPort(app)
    nativeBridgePort(device, app)
  })
  .catch(error => {
    // TODO: Display error to user or handle errors better
    const { code } = error
    switch (code) {
      case 'failed-precondition':
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        break

      case 'unimplemented':
        // The current browser does not support all of the
        // features required to enable persistence
        break

      default:
        // Throw up the errors
        throw error
    }
  })

export const userDefaultURL = '/assets/images/userDefault.gif'

// Returns main.XXXX.chunk.js or null in development
const getLocalBundleID = () => {
  const scripts = document.getElementsByTagName('script')
  for (let n = 0; n < scripts.length; n++) {
    const src = scripts[n].src || ''
    const matches = src.match(/\/static\/js\/(main\.\S+\.js)/)
    if (matches) return matches[1]
  }

  return null
}
