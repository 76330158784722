/***
 * This file is placed as port but not yet implemented as a Port in elm yet
 * as it will cause a lot of Cmd msg flying around
 * However, implementing as port will provide type safety
 * (at least in passing the params to JS)
 *
 * Note:
 * Returns either a file or a blob which both have very similar APIs
 * **/

export const compressImage = (file, maxWidth) => {
  const allowedMimeTypes = [
    "image/gif",
    "image/jpeg",
    "image/jpg",
    "image/png",
  ];
  const mimeType = file.type;

  return new Promise((resolve, reject) => {
    // Check that the file is an image type
    if (!allowedMimeTypes.includes(mimeType)) {
      reject(new Error(`${mimeType} is not accepted. File must be an image.`));
    }

    // don't compress animated gif
    if (mimeType === "image/gif") {
      return resolve(file);
    }

    // Don't resize if file is already small (<200kb)
    if (file.size < 200000) {
      return resolve(file);
    }

    // Start resizing process
    // We have a bit of the callback hell here...
    const image = new Image();
    image.onload = () => {
      EXIF.getData(image, () => {
        const canvas = document.createElement("canvas");

        let width = image.width;
        let height = image.height;
        let scale = 1;
        if (width > maxWidth) {
          scale = maxWidth / width;
          height = (maxWidth * height) / width;
          width = maxWidth;
        }

        const ctx = canvas.getContext("2d");
        ctx.canvas.width = width;
        ctx.canvas.height = height;

        const allMetaData = EXIF.getAllTags(image);
        const exifOrientation = allMetaData.Orientation;
        switch (exifOrientation) {
          case 3:
            // 180 degree
            ctx.transform(-1, 0, 0, -1, width, height);
            break;
          case 6:
            // -90 degree
            ctx.canvas.width = height;
            ctx.canvas.height = width;
            ctx.transform(0, 1, -1, 0, height, 0);
            break;
          case 8:
            // 90 degree
            ctx.canvas.width = height;
            ctx.canvas.height = width;
            ctx.transform(0, -1, 1, 0, 0, width);
            break;
          default:
        }
        ctx.scale(scale, scale);

        ctx.drawImage(image, 0, 0);
        canvas.toBlob((blob) => {
          window.URL.revokeObjectURL(image.src);
          resolve(file.size < blob.size ? file : blob);
        }, mimeType);
      });
    };

    image.src = window.URL.createObjectURL(file);
  });
};

// Helpers
export const getMimeType = (imageDataBase64) =>
  /^data:(.*);base64/.exec(imageDataBase64)[1];
