const _elmFirebaseDebugFlag = false // Turn on to see debugging messages

export const handle = (elmFirebaseReceivePortSend, request) => {
  if (_elmFirebaseDebugFlag) console.log(request)
  const { command, email, password, displayName, photoURL } = request
  const _sendErrorToElm = sendErrorToElm(elmFirebaseReceivePortSend, command)
  const _sendToElm = sendToElm(elmFirebaseReceivePortSend, command)
  const auth = window.firebase.auth()

  switch (command) {
    case 'CreateUserWithEmailAndPassword':
      return auth
        .createUserWithEmailAndPassword(email, password)
        .then(data => _sendToElm(toUserType(data.user)))
        .catch(_sendErrorToElm)

    case 'SendEmailVerification':
      return auth.currentUser
        .sendEmailVerification()
        .then(() => _sendToElm('OK'))
        .catch(_sendErrorToElm)

    case 'SendPasswordResetEmail':
      return auth
        .sendPasswordResetEmail(email)
        .then(() => _sendToElm('OK'))
        .catch(_sendErrorToElm)

    case 'SignInAnonymously':
      return auth
        .signInAnonymously()
        .then(data => _sendToElm(toUserType(data.user)))
        .catch(_sendErrorToElm)

    case 'SignInWithEmailAndPassword':
      return auth
        .signInWithEmailAndPassword(email, password)
        .then(data => _sendToElm(toUserType(data.user)))
        .catch(_sendErrorToElm)

    case 'SignOut':
      return runBeforeSignOutCallbacks()
        .then(() => auth.signOut())
        .then(() => _sendToElm('OK'))
        .catch(_sendErrorToElm)

    case 'UpdatePassword':
      return auth.currentUser
        .updatePassword(password)
        .then(() => _sendToElm('OK'))
        .catch(_sendErrorToElm)

    case 'UpdateProfile':
      return auth.currentUser
        .updateProfile({ displayName, photoURL })
        .then(() => _sendToElm('OK'))
        .catch(_sendErrorToElm)

    case 'Delete':
      return runBeforeSignOutCallbacks()
        .then(() => auth.currentUser.delete())
        .then(() => _sendToElm('OK'))
        .catch(_sendErrorToElm)

    case 'OnAuthStateChanged':
      return auth.onAuthStateChanged(user => {
        _sendToElm(toUserType(user))
      })

    default:
      const error = new Error(`Unknown command to execute: ${command}`)
      error.code = 'ElmFirebaseAuthInternal'
      return _sendErrorToElm(error)
  }
}

// Exposes a beforeSignOut lifecycle
// This is required by other scripts who needs to do stuff
// before we sign out the user
// Eg. Set user offline when user signs out
let beforeSignOutCallbacks = []
export const beforeSignOut = callback => beforeSignOutCallbacks.push(callback)
const runBeforeSignOutCallbacks = () => {
  const user = window.firebase.auth().currentUser
  if (user) {
    return Promise.all(beforeSignOutCallbacks.map(callback => callback(user)))
      .then(() => (beforeSignOutCallbacks = []))
      .catch(error => {
        console.error('Error handling beforeSignOut')
        console.error(error)
        beforeSignOutCallbacks = []
      })
  } else {
    return Promise.resolve()
  }
}

export const toUserType = data => {
  if (data) {
    return {
      displayName: data.displayName,
      uid: data.uid,
      email: data.email,
      emailVerified: data.emailVerified,
      photoURL: data.photoURL,
      isAnonymous: data.isAnonymous
    }
  } else {
    return null
  }
}

const sendToElm = (elmFirebaseReceivePortSend, command) => data => {
  elmFirebaseReceivePortSend(
    JSON.stringify({
      responseType: 'auth',
      command: command,
      data: data
    })
  )
}

const sendErrorToElm = (elmFirebaseReceivePortSend, command) => error => {
  const { code, message } = error
  const elmErrorObject = {
    responseType: 'auth',
    command: command,
    error: {
      code: code,
      message: message
    }
  }

  if (_elmFirebaseDebugFlag) console.error(elmErrorObject)
  elmFirebaseReceivePortSend(JSON.stringify(elmErrorObject))
}
