import { compressImage } from "../../../Lib/Ports/compressImage.js";

export const profilePort = (app) => {
  const firebase = window.CoreFirebase;
  const firebaseAuth = firebase.auth();
  const firebaseStorage = firebase.storage();
  const db = firebase.firestore();

  app.ports.uploadImage.subscribe((params) => {
    const user = firebaseAuth.currentUser;
    if (!user) return;

    const { uid } = user;
    const { kind, fileInputID } = params;

    if (kind === "Avatar") {
      uploadAvatar(app, uid, fileInputID);
    } else {
      uploadPhotos(app, uid, kind, fileInputID);
    }
  });

  app.ports.uploadMobileImage.subscribe((params) => {
    const user = firebaseAuth.currentUser;
    if (!user) return;

    const { uid } = user;
    const { kind, image, thumbnail } = params;

    if (kind === "Avatar") {
      uploadMobileAvatar(app, uid, image);
    } else {
      uploadMobilePhotos(app, uid, kind, image, thumbnail);
    }
  });

  app.ports.deleteImage.subscribe((params) => {
    const user = firebaseAuth.currentUser;
    if (!user) return;
    const { uid } = user;

    const { kind, photoID, url, thumbnailUrl } = params;
    const collectionName = kind === "Public" ? "publicPhotos" : "privatePhotos";
    const profileRef = db.doc(`profiles/${uid}`);
    const imageRef = profileRef.collection(collectionName).doc(photoID);

    return Promise.all([
      imageRef.delete(),
      firebaseStorage.refFromURL(url).delete(),
      firebaseStorage.refFromURL(thumbnailUrl).delete(),
    ]);
  });
};

const uploadAvatar = (app, uid, fileInputID) => {
  const firebase = window.CoreFirebase;
  const db = firebase.firestore();

  const file = getFile(fileInputID);
  // fail silently if the file is not found
  // eg. User has navigated away
  if (!file) return;
  let objectUrl = null;
  let avatarUrl = null;

  const profileRef = db.doc(`profiles/${uid}`);
  return compressImage(file, 600)
    .then((blob) => {
      objectUrl = window.URL.createObjectURL(blob);
      app.ports.profilePhotoUploadStatus.send({
        kind: "Avatar",
        photo: {
          id: "",
          url: objectUrl,
          thumbnailUrl: "",
          createdAt: 0,
        },
      });

      return blob;
    })
    .then((blob) => uploadToStorage(`/users/avatars/${uid}`, blob))
    .then((_) => getDownloadURL(`/users/avatars/${uid}`))
    .then((url) => {
      avatarUrl = url;
    })
    .then(() => preloadImage(avatarUrl))
    .then(() => {
      app.ports.profilePhotoUploadStatus.send({
        kind: "Avatar",
        photo: {
          id: "",
          url: avatarUrl,
          thumbnailUrl: "",
          createdAt: 0,
        },
      });
    })
    .then(() => profileRef.update({ avatar: avatarUrl }))
    .then(() => window.URL.revokeObjectURL(objectUrl))
    .catch((error) => {
      console.error(error);
      window.URL.revokeObjectURL(objectUrl);
    });
};

const uploadPhotos = (app, uid, kind, fileInputID) => {
  const firebase = window.CoreFirebase;
  const firebaseStorage = firebase.storage();
  const db = firebase.firestore();
  const dbTimestamp = firebase.firestore.FieldValue.serverTimestamp();

  const collectionName = kind === "Public" ? "publicPhotos" : "privatePhotos";
  const profileRef = db.doc(`profiles/${uid}`);
  const photoID = profileRef.collection(collectionName).doc().id;
  const thumbnailName = photoID + "_thumb";
  const imageRef = profileRef.collection(collectionName).doc(photoID);
  const imagePath = `/users/${collectionName}/${uid}/${photoID}`;
  const thumbnailPath = `/users/${collectionName}/${uid}/${thumbnailName}`;
  const file = getFile(fileInputID);
  // fail silently if the file is not found
  // eg. User has navigated away
  if (!file) return;
  let objectUrl = null;
  let imageUrl = null;
  let thumbnailUrl = null;

  return (
    Promise.all([compressImage(file, 1280), compressImage(file, 100)])
      .then(([image, thumbnail]) => {
        objectUrl = window.URL.createObjectURL(image);
        app.ports.profilePhotoUploadStatus.send({
          kind: kind,
          photo: {
            id: photoID,
            url: objectUrl,
            thumbnailUrl: objectUrl,
            createdAt: Date.now(),
          },
        });

        return Promise.all([
          uploadToStorage(thumbnailPath, thumbnail),
          uploadToStorage(imagePath, image),
        ]);
      })
      .then((_) =>
        Promise.all([getDownloadURL(imagePath), getDownloadURL(thumbnailPath)])
      )
      .then(([image, thumbnail]) => {
        // Mutate closure variables
        imageUrl = image;
        thumbnailUrl = thumbnail;
      })
      // Preload the image before passing
      // the firebaseStorage URL back to Elm
      // to prevent flicking
      .then(() => preloadImage(imageUrl))
      .then(() => {
        app.ports.profilePhotoUploadStatus.send({
          kind: kind,
          photo: {
            id: photoID,
            url: imageUrl,
            thumbnailUrl: thumbnailUrl,
            createdAt: Date.now(),
          },
        });
      })
      .then(() =>
        imageRef.set({
          url: imageUrl,
          thumbnailUrl: thumbnailUrl,
          createdAt: dbTimestamp,
        })
      )
      .then(() => {
        // Release the memory for objectUrl
        window.URL.revokeObjectURL(objectUrl);
      })
      .catch((error) => {
        console.error(error);
        window.URL.revokeObjectURL(objectUrl);
        if (imageUrl) firebaseStorage.refFromURL(imageUrl).delete();
        if (thumbnailUrl) firebaseStorage.refFromURL(thumbnailUrl).delete();
        imageRef.delete();
      })
  );
};

const uploadMobileAvatar = (app, uid, image) => {
  const firebase = window.CoreFirebase;
  const db = firebase.firestore();
  const profileRef = db.doc(`profiles/${uid}`);

  let avatarUrl = null;

  app.ports.profilePhotoUploadStatus.send({
    kind: "Avatar",
    photo: {
      id: "",
      url: image,
      thumbnailUrl: "",
      createdAt: 0,
    },
  });

  return uploadBase64ToStorage(`/users/avatars/${uid}`, image)
    .then((_) => getDownloadURL(`/users/avatars/${uid}`))
    .then((url) => {
      avatarUrl = url;
    })
    .then(() => preloadImage(avatarUrl))
    .then(() => {
      app.ports.profilePhotoUploadStatus.send({
        kind: "Avatar",
        photo: {
          id: "",
          url: avatarUrl,
          thumbnailUrl: "",
          createdAt: 0,
        },
      });
    })
    .then(() => profileRef.update({ avatar: avatarUrl }))
    .catch((error) => {
      console.error(error);
    });
};

const uploadMobilePhotos = (app, uid, kind, image, thumbnail) => {
  const firebase = window.CoreFirebase;
  const firebaseStorage = firebase.storage();
  const db = firebase.firestore();
  const dbTimestamp = firebase.firestore.FieldValue.serverTimestamp();

  const collectionName = kind === "Public" ? "publicPhotos" : "privatePhotos";
  const profileRef = db.doc(`profiles/${uid}`);
  const photoID = profileRef.collection(collectionName).doc().id;
  const thumbnailName = photoID + "_thumb";
  const imageRef = profileRef.collection(collectionName).doc(photoID);
  const imagePath = `/users/${collectionName}/${uid}/${photoID}`;
  const thumbnailPath = `/users/${collectionName}/${uid}/${thumbnailName}`;

  let imageUrl = null;
  let thumbnailUrl = null;

  app.ports.profilePhotoUploadStatus.send({
    kind: kind,
    photo: {
      id: photoID,
      url: image,
      thumbnailUrl: image,
      createdAt: Date.now(),
    },
  });

  return Promise.all([
    uploadBase64ToStorage(thumbnailPath, thumbnail),
    uploadBase64ToStorage(imagePath, image),
  ])
    .then((_) =>
      Promise.all([getDownloadURL(imagePath), getDownloadURL(thumbnailPath)])
    )
    .then(([image, thumbnail]) => {
      imageUrl = image;
      thumbnailUrl = thumbnail;
    })
    .then(() => preloadImage(imageUrl))
    .then(() => {
      app.ports.profilePhotoUploadStatus.send({
        kind: kind,
        photo: {
          id: photoID,
          url: imageUrl,
          thumbnailUrl: thumbnailUrl,
          createdAt: Date.now(),
        },
      });
    })
    .then(() =>
      imageRef.set({
        url: imageUrl,
        thumbnailUrl: thumbnailUrl,
        createdAt: dbTimestamp,
      })
    )
    .catch((error) => {
      console.error(error);
      if (imageUrl) firebaseStorage.refFromURL(imageUrl).delete();
      if (thumbnailUrl) firebaseStorage.refFromURL(thumbnailUrl).delete();
      imageRef.delete();
    });
};

const uploadToStorage = (path, blob) => {
  const firebase = window.CoreFirebase;
  const firebaseStorage = firebase.storage();

  return firebaseStorage
    .ref(path)
    .put(blob, { cacheControl: "public,max-age=31556926" });
};

const uploadBase64ToStorage = (path, imageData) => {
  const firebase = window.CoreFirebase;
  const firebaseStorage = firebase.storage();

  return firebaseStorage.ref(path).putString(imageData, "data_url", {
    cacheControl: "public,max-age=31556926",
  });
};

const getDownloadURL = (path) => {
  const firebase = window.CoreFirebase;
  const firebaseStorage = firebase.storage();

  return firebaseStorage.ref(path).getDownloadURL();
};

const preloadImage = (imageUrl) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = resolve;
    image.src = imageUrl;
  });
};

const getFile = (fileInputID) => {
  const field = document.getElementById(fileInputID) || { files: [] };
  return field.files[0];
};
