// We created this function for the purpose of exposing it for testing
window.attachFormAndSubmit = form => {
  document.body.appendChild(form)
  form.submit()
}

export const membershipPort = app => {
  app.ports.checkoutToMultiCards.subscribe(params => {
    const { merchantID, pageOrderID, checkoutUrl, userID, plan } = params
    const { name, amountInCents, action } = plan
    const planPriceString = convertCentsToPriceString(amountInCents)
    const firstMonthPriceString =
      action === 'CreateNewWithTrial' ? '1.00' : planPriceString

    const formID = 'multicard-form-xjsUHslm'
    const existingForm = document.getElementById(formID)
    if (existingForm) {
      document.body.removeChild(existingForm)
    }

    const form = document.createElement('form')
    form.id = formID
    form.style.display = 'none'
    form.action = checkoutUrl
    form.method = 'post'

    const fields = [
      { name: 'mer_id', value: merchantID },
      { name: 'mer_url_idx', value: pageOrderID },
      { name: 'item1_desc', value: formatProductName(action, name) },
      { name: 'item1_qty', value: '1' },
      { name: 'item1_price', value: firstMonthPriceString }, // amount to collect NOW
      { name: 'userID', value: userID }, // custom field passed by us
      { name: 'returnUrl', value: window.location.href } // custom field passed by us
    ]

    if (action === 'CreateNew' || action === 'CreateNewWithTrial') {
      // start monthly rebill at ${planPriceString} after 30 days
      fields.push({
        name: 'rebill_type',
        value: `monthly-${planPriceString}-30`
      })
      fields.push({
        name: 'rebill_desc',
        value: formatProductName(action, name)
      })
    }

    // Create the form and fields DOM
    fields.forEach(field => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = field.name
      input.value = field.value
      form.appendChild(input)
    })

    window.attachFormAndSubmit(form)
  })

  // convert 2000 to 20.00
  const convertCentsToPriceString = amountInCents => {
    const centsString = String(amountInCents).padStart(3, '0')
    return centsString.slice(0, -2) + '.' + centsString.slice(-2)
  }

  // Format the product name
  // SILVER => Silver Membership
  const formatProductName = (action, name) => {
    const formattedName = name[0].toUpperCase() + name.slice(1).toLowerCase()
    if (action === 'CreateNew' || action === 'CreateNewWithTrial') {
      return `${formattedName} Monthly Membership`
    } else if (action === 'UpgradeTo') {
      return `Pro-rated Upgrade to ${formattedName} Membership`
    } else {
      return 'Error Product Name'
    }
  }
}
