export const contentPort = app => {
  app.ports.updateMetaData.subscribe(({ description, keywords }) => {
    document
      .getElementsByTagName('meta')
      .namedItem('description')
      .setAttribute('content', description)
    document
      .getElementsByTagName('meta')
      .namedItem('keywords')
      .setAttribute('content', keywords)
  })
}
