const _elmFirebaseSubscriptionIDs = {}
const _elmFirebaseDebugFlag = false // Set to true to see debugging info

// Get queries to execute
export const handle = (elmFirebaseReceivePortSend, request) => {
  if (_elmFirebaseDebugFlag) _printRequest(request)
  const db = window.firebase.firestore()
  const { queryID, ref, clauses = [], cmd } = request
  const [dbRef, path] = ref

  // TODO: return if listener subscription already exists
  // TODO: allow queryID == "noop" for commands that Elm does not want to listen to
  // if (_elmFirebaseSubscriptionIDs[queryID] != null) return

  try {
    const query = buildQuery(db[dbRef](path), clauses)

    _elmFirebaseSubscriptionIDs[queryID] = executeQuery(
      elmFirebaseReceivePortSend,
      queryID,
      cmd,
      query
    )
  } catch (error) {
    error.code = error.code || 'elm-firebase-internal'
    sendErrorToElm(elmFirebaseReceivePortSend, queryID, cmd[0])(error)
  }
}

// Stop listening to queries
export const unsubscribe = queryID => {
  const listener = _elmFirebaseSubscriptionIDs[queryID]
  if (listener) {
    listener()
    delete _elmFirebaseSubscriptionIDs[queryID]
    if (_elmFirebaseDebugFlag) console.log(`Stopped ${queryID}`)
  } else {
    // do nothing
  }
}

const executeQuery = (elmFirebaseReceivePortSend, queryID, cmd, query) => {
  const [command, ...args] = cmd
  const data = args[0] ? replaceSpecialPlaceHolder(JSON.parse(args[0])) : null
  const _sendToElm = sendToElm(elmFirebaseReceivePortSend, queryID, command)
  const _sendErrorToElm = sendErrorToElm(
    elmFirebaseReceivePortSend,
    queryID,
    command
  )
  const returnVoid = () => _sendToElm(emptyDoc)

  switch (command) {
    case 'GetDoc':
      return query
        .get()
        .then(snapshot => _sendToElm(elmDocSnapshot(snapshot)))
        .catch(_sendErrorToElm)

    case 'SetDoc':
      return query
        .set(data)
        .then(returnVoid)
        .catch(_sendErrorToElm)

    case 'SetDocWithMerge':
      return query
        .set(data, { merge: true })
        .then(returnVoid)
        .catch(_sendErrorToElm)

    case 'UpdateDoc':
      return query
        .update(data)
        .then(returnVoid)
        .catch(_sendErrorToElm)

    case 'DeleteDoc':
      return query
        .delete()
        .then(returnVoid)
        .catch(_sendErrorToElm)

    case 'OnSnapshotDoc':
      return query.onSnapshot(
        snapshot => _sendToElm(elmDocSnapshot(snapshot)),
        _sendErrorToElm
      )

    case 'AddDoc':
      return query.add(data).catch(_sendErrorToElm)

    case 'GenerateID':
      return _sendToElm(query.doc().id)

    case 'GetCollection':
      return query
        .get()
        .then(snapshots => _sendToElm(elmQuerySnapshot(snapshots)))
        .catch(_sendErrorToElm)

    case 'OnSnapshotCollection':
      return query.onSnapshot(
        data, // { includeMetadataChanges: Bool }
        snapshots => _sendToElm(elmQuerySnapshot(snapshots)),
        _sendErrorToElm
      )

    default:
      const error = new Error(`Unknown command to execute: ${command}`)
      error.code = 'elm-firebase-internal'
      return _sendErrorToElm(error)
  }
}

const buildQuery = (db, clauses) => {
  if (clauses.length <= 0) return db
  const [clause, ...args] = clauses[0]
  const rest = clauses.slice(1)

  switch (clause) {
    case 'where':
      return buildQuery(db.where(...args), rest)

    case 'whereTime':
      args[2] = window.firebase.firestore.Timestamp.fromMillis(args[2])
      return buildQuery(db.where(...args), rest)

    case 'whereBool':
      args[2] = args[2] === 'true'
      return buildQuery(db.where(...args), rest)

    case 'limit':
      const limit = parseInt(args[0])
      return buildQuery(db.limit(limit), rest)

    case 'orderBy':
      return buildQuery(db.orderBy(...args), rest)

    default:
      throw new Error(`Invalid Clause: ${clauses[0]}`)
  }
}

// ********** Helpers *************

const elmQuerySnapshot = snapshots => {
  return {
    size: snapshots.size,
    empty: snapshots.empty,
    docs: snapshots.docs.map(elmDocSnapshot),
    docChanges: snapshots.docChanges().map(elmDocumentChange)
  }
}

const elmDocSnapshot = doc => {
  const convertedData = convertTimestampToMillis(doc.data()) || {}

  return {
    id: doc.id,
    exists: doc.exists,
    data: JSON.stringify(convertedData),
    metadata: doc.metadata
  }
}

const elmDocumentChange = ({ type, doc }) => {
  return {
    type_: type,
    doc: elmDocSnapshot(doc)
  }
}

const convertTimestampToMillis = docData => {
  Object.keys(docData || {}).forEach(fieldName => {
    const value = docData[fieldName]
    // typeof null is an object!!!!!
    if (value == null || typeof value !== 'object') return

    if (typeof value.toMillis === 'function') {
      docData[fieldName] = value.toMillis()
    } else if (Object.keys(value).length > 0) {
      convertTimestampToMillis(value)
    }
  })

  return docData
}

// Replace placeholders such as serverTimestamp
const replaceSpecialPlaceHolder = data => {
  if (typeof data === 'object') {
    Object.keys(data).forEach(function(key) {
      var value = data[key]
      if (typeof value === 'string') {
        if (value === 'ELM-FIREBASE::ENCODED-SERVER-TIME-STAMP') {
          data[key] = window.firebase.firestore.FieldValue.serverTimestamp()
        } else if (value.startsWith('ELM-FIREBASE::ENCODED-TIME|')) {
          const milliseconds = parseInt(value.split('|')[1])
          data[key] = window.firebase.firestore.Timestamp.fromMillis(
            milliseconds
          )
        } else if (value.startsWith('ELM-FIREBASE::ENCODED-INCREMENT|')) {
          const number = parseInt(value.split('|')[1])
          data[key] = window.firebase.firestore.FieldValue.increment(number)
        }
      } else if (typeof data[key] === 'object') {
        data[key] = replaceSpecialPlaceHolder(data[key])
      } else {
        // do nothing
      }
    })

    return data
  } else {
    return data
  }
}

const sendToElm = (elmFirebaseReceivePortSend, queryID, command) => data => {
  elmFirebaseReceivePortSend(
    JSON.stringify({
      responseType: 'firestore',
      queryID: queryID,
      command: command,
      data: data
    })
  )
}

const sendErrorToElm = (
  elmFirebaseReceivePortSend,
  queryID,
  command
) => error => {
  const { code, message } = error
  const elmErrorObject = {
    responseType: 'firestore',
    queryID: queryID,
    command: command,
    error: {
      code: code,
      message: message
    }
  }

  if (_elmFirebaseDebugFlag) console.error(elmErrorObject)
  elmFirebaseReceivePortSend(JSON.stringify(elmErrorObject))
}

// Needed to form the response for void returning operations
// such as setDoc, deleteDoc, etc
const emptyDoc = {
  id: 'EMPTYDOC',
  exists: false,
  data: '',
  metadata: {
    fromCache: false,
    hasPendingWrites: false
  }
}

const _printRequest = request => {
  const { queryID, ref, clauses, cmd } = request
  const output = []
  output.push('QueryID: ' + queryID)
  output.push(ref.join(' '))
  if (clauses && clauses.length > 0)
    output.push(clauses.map(c => '\t' + c.join(' ')).join('\n'))
  output.push(cmd.join(' '))
  console.log(output.join('\n'))
}
