import { beforeSignOut } from '../../../lib/elm-firebase/src/Firebase/ports'

/***
 * Update the online status of the current user
 * FireStore does not have this capability
 * We have to use Firebase database to do it
 * See: https://firebase.google.com/docs/firestore/solutions/presence
 *
 * In mobile, if user kill app, status will be changed to offline
 * In mobile, if user push app in background, status will be changed to offline after 15 mins
 *
 * TODO: Don't track online status if the user's profile is not completed
 * TODO: Edge case when user goes background, sometimes onlineStatus is offline
 * but coming back from background, the onlineStatus is not changed to online
 ***/

export const onlineStatusHandler = (firebaseAuth, firebaseDb) => {
  let onlineStatusListener = null

  firebaseAuth.onAuthStateChanged(user => {
    if (user) {
      const { uid } = user
      const userStatusDatabaseRef = firebaseDb.ref(`/onlineStatus/${uid}`)

      // Create a reference to the special '.info/connected' path in
      // Realtime Database. This path returns `true` when connected
      // and `false` when disconnected.
      onlineStatusListener = firebaseDb
        .ref('.info/connected')
        .on('value', snapshot => {
          // If we're not currently connected, don't do anything.
          if (snapshot && snapshot.val() === false) {
            return
          }

          // If we are currently connected, then use the 'onDisconnect()'
          // method to add a set which will only trigger once this
          // client has disconnected by closing the app,
          // losing internet, or any other means.
          userStatusDatabaseRef
            .onDisconnect()
            .set({ status: 'Offline' })
            .then(function() {
              // The promise returned from .onDisconnect().set() will
              // resolve as soon as the server acknowledges the onDisconnect()
              // request, NOT once we've actually disconnected:
              // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

              // We can now safely set ourselves as 'online' knowing that the
              // server will mark us as offline once we lose connection.
              userStatusDatabaseRef.set({ status: 'Online' })
            })
        })

      // When user logout, the uid and permission are lost
      // We need to use a beforeLogout lifecycle to mark the user offline
      // before the user logs out
      beforeSignOut(() => {
        if (onlineStatusListener != null) {
          onlineStatusListener()
          onlineStatusListener = null
        }

        return userStatusDatabaseRef.set({ status: 'Offline' })
      })
    } else {
      // We cannot call onlineStatusListener()
      // because user has no permission to cancel the listener
      // So do nothing here
    }
  })
}
