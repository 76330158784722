/**
 * NativeBridge contains all the API to interface with the mobile native
 * functions such as camera and push notifications
 *
 * Note:
 * If user is starting the mobile app from a push notification
 * and the mobile app is not started yet,
 * the push notification will be sent via flag to the elm app
 **/

export const initialPushNotificationJSON = () => {
  const url = new URL(window.location)
  return url.searchParams.get('p')
    ? decodeURIComponent(url.searchParams.get('p'))
    : null
}

export const initialDevice = () => {
  const url = new URL(window.location)
  const os = url.searchParams.get('os')

  if (os === 'ios') {
    return 'MobileApp Ios'
  } else if (os === 'android') {
    return 'MobileApp Android'
  } else {
    // User using a WebApp
    const userAgent = navigator.userAgent
    if (/android/i.test(userAgent)) {
      return 'WebApp Mobile Android'
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'WebApp Mobile Ios'
    } else if (window.innerWidth < 768) {
      return 'WebApp Mobile Android'
    } else {
      return 'WebApp Desktop'
    }
  }
}

export const nativeVersion = () => {
  const url = new URL(window.location)
  const version = url.searchParams.get('version')
  return version || '0.0.0'
}

// Integration with Elm Ports
export const nativeBridgePort = (device, app) => {
  // Define a top level namespace
  // Also add app into nativeBridge
  const nativeBridge = {}
  const isAndroidMobileApp = device === 'MobileApp Android'
  const isIosMobileApp = device === 'MobileApp Ios'
  const isWebApp = device.startsWith('WebApp')

  app.ports.fcmSubscribeTopic.subscribe(topic => {
    if (isAndroidMobileApp) {
      window.JavaBridge.fcmSubscribeTopic(topic)
    } else if (isIosMobileApp) {
      window.webkit.messageHandlers.fcmSubscribeTopic.postMessage(topic)
    }
  })

  app.ports.fcmUnSubscribeTopic.subscribe(topic => {
    if (isAndroidMobileApp) {
      window.JavaBridge.fcmUnSubscribeTopic(topic)
    } else if (isIosMobileApp) {
      window.webkit.messageHandlers.fcmUnSubscribeTopic.postMessage(topic)
    }
  })

  app.ports.startCameraSession.subscribe(startCameraParams => {
    const params = JSON.stringify(startCameraParams)
    if (isAndroidMobileApp) {
      window.JavaBridge.startCameraSession(params)
    } else if (isIosMobileApp) {
      window.webkit.messageHandlers.startCameraSession.postMessage(params)
    }
  })

  nativeBridge.onCameraSessionEnd = data =>
    app.ports.onCameraSessionEnd.send(data)

  nativeBridge.onPushNotification = pushNotification =>
    app.ports.onPushNotification.send(pushNotification)

  if (isWebApp) {
    window.addEventListener('focus', () => {
      app.ports.onAppStatusChangedPort.send('active')
    })
    window.addEventListener('blur', () => {
      app.ports.onAppStatusChangedPort.send('background')
    })

    // If user switch focus while Elm is still loading,
    // Elm will have an outdated status of 'active'
    if (!document.hasFocus())
      app.ports.onAppStatusChangedPort.send('background')
  } else {
    nativeBridge.onAppStatusChanged = status => {
      app.ports.onAppStatusChangedPort.send(status)
    }
  }

  app.ports.sendCustom.subscribe(callFunctionParams => {
    const params = JSON.stringify(callFunctionParams)
    if (isAndroidMobileApp) {
      window.JavaBridge.sendCustom(params)
    } else if (isIosMobileApp) {
      window.webkit.messageHandlers.sendCustom.postMessage(params)
    }
  })

  nativeBridge.onCustomMsg = data => app.ports.onCustomMsg.send(data)

  /** ************** Expose to global *****************/
  window.nativeBridge = nativeBridge

  if (isAndroidMobileApp) {
    window.JavaBridge.nativeBridgeIsReady()
  } else if (isIosMobileApp) {
    window.webkit.messageHandlers.nativeBridgeIsReady.postMessage(null)
  }
}
