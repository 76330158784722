import { db, firebaseAuth, firebaseStorage } from '../../index.js'
import { compressImage } from '../../../../core/src/Lib/Ports/compressImage.js'

export const messagePort = app => {
  app.ports.sendRemoteImageMessageWithBase64.subscribe(params => {
    const user = firebaseAuth.currentUser
    if (!user) return

    const { roomID, partnerID, messageID, createdAt, imageData } = params
    const storageRef = getStorageRef(roomID, messageID)

    uploadBase64ToStorage(storageRef, imageData)
      .then(_ => storageRef.getDownloadURL())
      .then(imageUrl => saveMessage(messageID, partnerID, createdAt, imageUrl))
      .then(imageUrl => {
        // Tell Elm to load the remote URL
        app.ports.remoteImageUploadStatus.send({
          partnerID,
          messageID,
          imageUrl: imageUrl
        })
      })
      .catch(error => {
        Promise.resolve(storageRef.delete()).catch(() => {})
        console.error(error)
      })
  })

  app.ports.sendRemoteImageMessage.subscribe(params => {
    const user = firebaseAuth.currentUser
    if (!user) return

    const { roomID, partnerID, messageID, createdAt, fileInputID } = params
    const storageRef = getStorageRef(roomID, messageID)
    const field = document.getElementById(fileInputID) || { files: [] }
    const file = field.files[0]
    // fail silently if the file is not found
    // eg. User has navigated away
    if (!file) return
    let objectUrl = null

    // Rotate and compress the image first
    return compressImage(file, 1280)
      .then(blob => {
        // Tell elm to load the local file URL
        objectUrl = window.URL.createObjectURL(blob)
        app.ports.remoteImageUploadStatus.send({
          partnerID,
          messageID,
          imageUrl: objectUrl
        })

        return blob
      })
      .then(blob => uploadToStorage(storageRef, blob))
      .then(_ => storageRef.getDownloadURL())
      .then(imageUrl => saveMessage(messageID, partnerID, createdAt, imageUrl))
      .then(imageUrl => {
        // Tell Elm to load the remote URL
        app.ports.remoteImageUploadStatus.send({
          partnerID,
          messageID,
          imageUrl: imageUrl
        })
      })
      .then(() => {
        // Release the memory for objectUrl
        window.URL.revokeObjectURL(objectUrl)
      })
      .catch(error => {
        Promise.resolve(storageRef.delete()).catch(() => {})
        window.URL.revokeObjectURL(objectUrl)
        console.error(error)
      })
  })
}

const saveMessage = (messageID, partnerID, createdAt, imageUrl) => {
  const messageRef = db.doc(`/chat/history/messages/${messageID}`)
  return Promise.all([
    // Preload the image before passing
    // the firebaseStorage URL back to Elm
    // to prevent flicking
    preloadImage(imageUrl),
    // Set firestore message data
    messageRef.set(messageSnapshot(imageUrl, createdAt, partnerID))
  ]).then(() => imageUrl)
}

const preloadImage = imageUrl => {
  return new Promise(resolve => {
    const image = new Image()
    image.onload = resolve
    image.src = imageUrl
  })
}

const getStorageRef = (roomID, messageID) => {
  return firebaseStorage.ref(`/chats/rooms/${roomID}/${messageID}`)
}

const uploadToStorage = (storageRef, blob) => {
  return storageRef.put(blob, {
    cacheControl: 'public,max-age=31556926',
    contentType: blob.type
  })
}

const uploadBase64ToStorage = (storageRef, imageData) =>
  storageRef.putString(imageData, 'data_url', {
    cacheControl: 'public,max-age=31556926'
  })

const messageSnapshot = (imageUrl, createdAt, partnerID) => {
  const { uid, photoURL } = firebaseAuth.currentUser
  if (photoURL === 'https://happymatches.com/assets/images/sponsor.jpg') {
    return {
      receiverID: partnerID,
      sponsorID: uid,
      sugarID: partnerID,
      kind: 'Image',
      createdAt: window.firebase.firestore.Timestamp.fromMillis(createdAt),
      content: imageUrl
    }
  } else {
    return {
      receiverID: partnerID,
      sponsorID: partnerID,
      sugarID: uid,
      kind: 'Image',
      createdAt: window.firebase.firestore.Timestamp.fromMillis(createdAt),
      content: imageUrl
    }
  }
}
